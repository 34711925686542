const NFNStrings = {
    appName: "No Filter",
    appNameLg: "NO FILTER",
    fullName: "No Filter Network",
    fullNameLg: "NO FILTER NETWORK",
    title: "NoFilter.net",
    supportMail: "support@nofilter.net"
}

const NFLAAStrings = {
    appName: "NFL Alumni Media",
    fullName: "NFL Alumni Media",
    title: "NFL Alumni Media"
}

const OVIStrings = {
    appName: "OVI Interactive",
    appNameLg: "OVI INTERACTIVE",
    fullName: "OVI Interactive FZCO",
    fullNameLg: "OVI INTERACTIVE FZCO",
    title: "OVI Interactive",
    supportMail: "support@ovi.live"
}

export const strings = process.env.build && process.env.build === 'nfl-aa' ? NFLAAStrings : process.env.build && process.env.build === 'ovi' ? OVIStrings : NFNStrings;